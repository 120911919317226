import React from 'react';

// AboutUs component
const AboutUs = () => {
  return (
    <div id="AboutUs" className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900">
            SQUAD
          </h2>
          <p className="mt-4 text-lg lg:text-xl text-gray-700">
            Fostering a community of quantitative trailblazers:<br />
            Empowering students to push the boundaries of data-driven innovation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;